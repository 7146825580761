module.exports = {
    RANDOM_GGT_REWARD_RATE_CONFIG: {
        key: 'RANDOM_GGT_REWARD_RATE',
        default_data: [
            {
                min: 0,
                max: 0.01,
                rate: 20
            },
            {
                min: 0.01,
                max: 0.02,
                rate: 17.5
            },
            {
                min: 0.02,
                max: 0.05,
                rate: 50
            },
            {
                min: 0.1,
                max: 0.2,
                rate: 10
            },
            {
                min: 0.2,
                max: 0.5,
                rate: 0.8
            },
            {
                min: 0.5,
                max: 1,
                rate: 0.6
            },
            {
                min: 1,
                max: 2,
                rate: 0.5
            },
            {
                min: 2,
                max: 5,
                rate: 0.3
            },
            {
                min: 5,
                max: 9.99,
                rate: 0.2
            },
            {
                min: 9.99,
                max: 10,
                rate: 0.1
            },
        ]
    },

    MODULE_CATEGORY: 'mission'
}
