<template>
  <div class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Random GGT Reward Rate" class="mb-3" />
      </b-col>
    </b-row>
    <b-media>
      <section class="py-3">
        <b-card>
          <random-g-g-t-rate-table :config="config" @getConfig="getConfig" />
        </b-card>
      </section>
    </b-media>
    <b-pagination
      class="mt-auto"
      pills
      size="lg"
      align="center"
      v-model="current_page"
      :total-rows="total_items"
      :per-page="items_perpage"
    />
  </div>
</template>

<script>
import configService from '../../../config/service'
import { RANDOM_GGT_REWARD_RATE_CONFIG, MODULE_CATEGORY } from '../constants'
import RandomGGTRateTable from "./_components/RandomGGTRateTable.vue";

export default {
  components: {
    RandomGGTRateTable,
  },
  data() {
    return {
      current_page: 1,
      total_items: 0,
      items_perpage: 50,
      config: {},
    };
  },
  created() {
    this.getConfig()
  },
  methods: {
    async getConfig() {
      this.config = await configService.getByKey({ key: RANDOM_GGT_REWARD_RATE_CONFIG.key }, { default_data: RANDOM_GGT_REWARD_RATE_CONFIG.default_data, category: MODULE_CATEGORY })
    },
  },
};
</script>
